import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueSweetalert2 from 'vue-sweetalert2';
import VueImg from 'v-img';
import 'sweetalert2/dist/sweetalert2.min.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
  // Event listener to open gallery will be applied to <img> element
  openOn: 'click',
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
}
Vue.use(VueImg, vueImgConfig);
Vue.use(VueSweetalert2);
Vue.use(VueRouter);
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB3Qq8bMAdBAHvI1_Df7HE0YggDt_OJBvY',
    //key :'AIzaSyD-rmbfyc1ntqTnGtESZugPJi32ZeMCnaI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
})
const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: () => import("@/components/home/Home"), meta: { title: 'AGC Home' } },
    { path: '/contact-us', component: () => import("@/components/contactus/Contact") },
    { path: '/sale', component: () => import("@/components/sale/Sale") },
    { path: '/solar', component: () => import("@/components/solar/Solar") },
    { path: '/product', component: () => import("@/components/product/Product") },
    { path: '/product-detail', component: () => import("@/components/product_detail/ProductDetail") },
    { path: '/product-detail/:id', component: () => import("@/components/product_detail/ProductDetail") },
    { path: '/footprint', component: () => import("@/components/footprint/Footprint") },
    { path: '/footprint-detail/0', component: () => import("@/components/footprint_detail/FootprintDetail") },
    { path: '/footprint-detail/1', component: () => import("@/components/footprint_detail_1/FootprintDetail") },
    { path: '/footprint-detail/2', component: () => import("@/components/footprint_detail_2/FootprintDetail") },
    { path: '/footprint-detail/3', component: () => import("@/components/footprint_detail_3/FootprintDetail") },
    { path: '/career', component: () => import("@/components/career/Career") },
    { path: '/sustainability', component: () => import("@/components/sustainability/Sustainability") },
    { path: '/about-us', component: () => import("@/components/aboutUs/AboutUs") },
  ]
});

Vue.config.productionTip = false

new Vue({
  router,
  el: "#app",
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
